import { colors } from "@styles/colors";


export default `
  a {
    -webkit-tap-highlight-color: transparent;
    text-decoration: none;
color: ${colors.default};
    &[aria-current] {
      text-decoration: none;
      color: #FF671D;
    }
  }

  .go-back {
    &:before {
      content: '←';
      padding-right: 8px;
    }
  }

  &.work-item {
    display: inline-block;
    max-width: 28rem;
    line-height: 1.25;

    &:after {
      content: '→';
      padding-left: 0.5rem;
      visibility: hidden;
    }

    &:hover {

      &:after {
        visibility: visible;
      }
    }
  }

`
