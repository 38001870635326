import { typography } from "../text";


export default `
  h1, h2, h3, h4, h5, h6 {
    color: ${typography.headings};
    line-height: ${typography.dimensions.lineHeight.heading};
  }

  h1 {
    font-family: ${typography.families.headings};
    font-size: ${typography.dimensions.fontSizes.h1}rem;
    line-height: 1;

    .has_undeline & {
      padding-bottom: 1rem;
        }
  }

  h2 {
    font-size: ${typography.dimensions.fontSizes.h2}rem;
  }

  h3 {
    font-size: ${typography.dimensions.fontSizes.h3}rem;
  }

  h4 {
    font-size: ${typography.dimensions.fontSizes.h4}rem;
  }

  h5 {
    font-size: ${typography.dimensions.fontSizes.h5}rem;
  }

  h6 {
    font-weight: ${typography.weight.semiBold};
    font-size: ${typography.dimensions.fontSizes.h6}rem;
    margin-bottom: 1em;
    text-transform: uppercase;
  }
`
