
import { breakpoints } from '@styles/01-settings/settings.breakpoints'
import { bp } from '@styles/02-tools/tools.breakpoints'
import { rem } from '@styles/text'

export default `
  blockquote,  .blockquote {
    position: relative;
    font-size: ${rem(31)}rem;

    line-height: 37.42px;
    max-width: 578px;
    margin: 0 auto;

    p::before {
      content: "“";
      color: #FF671D;
      position: absolute;
      top: 0;
      left: 0;
      font-size: ${rem(61)}rem;
      margin-left: -0.75em;
      line-height: 1;
    }

    &.blockquote-wider {
      max-width: 863px;
    }

  cite {
    font-style: normal;
  }
}

  .blockquote {
    display: block;
    margin: 4rem 0;
    max-width: 863px;

    &::before {
      content: "“";
      color: #FF671D;
      position: absolute;
      top: 0;
      left: 0;
      font-size: ${rem(61)}rem;
      margin-left: -0.75em;
      line-height: 1;
    }
  }

`
