exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/news_post.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */),
  "component---src-templates-subpages-tsx": () => import("./../../../src/templates/subpages.tsx" /* webpackChunkName: "component---src-templates-subpages-tsx" */),
  "component---src-templates-workshops-index-tsx": () => import("./../../../src/templates/workshops_index.tsx" /* webpackChunkName: "component---src-templates-workshops-index-tsx" */),
  "component---src-templates-workshops-post-tsx": () => import("./../../../src/templates/workshops_post.tsx" /* webpackChunkName: "component---src-templates-workshops-post-tsx" */)
}

