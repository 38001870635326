import generic from './03-generic/'
import elements from './04-elements/'
import components from './08-components/'
import utilities from './09-utilities/'
import { createGlobalStyle } from 'styled-components'
import { rem } from './text'
import { breakpoint } from './02-tools/tools.breakpoints'

export const GlobalStyles = createGlobalStyle`
  ${generic}
  ${elements}
  ${components}
  ${utilities}

.customHtmlElement {
  #mc_embed_signup {
    width: revert;
  }
}


.homep-text2 {
  font-size: ${rem(50)}rem;
  span, div {
    display: inline-block;
  }
  span {
    color: #FF671D;
    margin: 0 0.1em;
    ${breakpoint.gt('md')`
    margin: 0 0.33em;
  `}
  }
    .second-line {
        ${breakpoint.gt('md')`
      transform: translateX(15rem);
  `}

      div:last-of-type div {
          span:last-of-type {
                ${breakpoint.gt('md')`
        margin: 0;
  `}
  }
      }
    }

}

`
